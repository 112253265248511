<template>
  <v-container>
    <v-row class="mb-2">
      <v-col cols="12" md="6">
        <v-skeleton-loader v-if="titleLoading" :loading="true" type="text" />
        <h1 v-else>{{ groupName }}'s Test List</h1>
      </v-col>
      <v-col cols="12" md="6">
        <v-form @submit.prevent="options.search = searchForm">
          <v-text-field
            v-model="searchForm"
            @click:append="clearSearch"
            :append-icon="searchForm ? 'mdi-close' : ''"
            prepend-icon="mdi-magnify"
            :label="$vuetify.lang.t('$vuetify.search')"
            single-line
            hide-details
          >
          </v-text-field>
        </v-form>
      </v-col>
    </v-row>
    <data-table
      :headers="headers"
      :items="tests"
      :options.sync="options"
      :server-items-length="totalData"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          first-icon="mdi-chevron-double-left"
          last-icon="mdi-chevron-double-right"
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
          :items-per-page-options="[25, 50, 100, -1]"
          :options="options"
          :pagination="pagination"
          :show-first-last-page="true"
          :show-current-page="true"
        />
      </template>
      <template v-slot:[`item.action`]="{ item: { groupId, testId } }">
        <div style="width: 100px">
          <v-btn
            :to="{
              name: 'Result.Group.TestResult',
              params: { group_id: groupId, test_id: testId }
            }"
            color="primary"
            class="mb-1"
            >Result</v-btn
          >
        </div>
      </template>
    </data-table>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import DataTable from "@/components/DataTable.js";
import Group from "@/services/group";

export default {
  name: "Result.Group.Test",
  data() {
    return {
      totalData: 0,
      tests: [],
      loading: true,
      options: {
        search: undefined
      },
      headers: [
        { text: "Test Name", value: "testName" },
        { text: "Action", value: "action", sortable: false }
      ],
      groupName: "",
      titleLoading: true,
      searchForm: null
    };
  },
  watch: {
    options: {
      handler() {
        this.getTests();
      },
      deep: true
    }
  },
  components: {
    DataTable
  },
  created() {
    this.setNavbarTitle("Test List");
    this.setTitle();
  },
  computed: {
    groupId() {
      return this.$route.params.group_id;
    }
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async getTests() {
      this.loading = true;
      this.tests = [];
      const tests = await Group.findTests(this.groupId, this.options);
      this.tests = tests.data.data.tests;
      this.totalData = tests.data.data.total_data;
      this.loading = false;
    },
    async setTitle() {
      const group = await Group.find(this.groupId);
      this.groupName = group.data.data.name;
      this.titleLoading = false;
    },
    clearSearch() {
      this.options.search = undefined;
      this.searchForm = null;
    }
  }
};
</script>
